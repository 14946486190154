<template>

  <div class="container">
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <h1 class="text-primary">Validador de XML</h1>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col>
        <div class="my-5">
          Verifica el archivo XML generado por DigitaFirma para validar integralmente
          la huella digital del documento, las firmas asociadas a el
          y las constancias de conservación de datos conforme a la NOM-151.
        </div>
      </vs-col>
    </vs-row>

    <form v-if="!result" @submit.prevent="validateXml" enctype="multipart/form-data">
      <input type="file" ref="fileInput" class="hidden" accept=".xml" @change="handleClick">
      <!--Todo: make this a component-->
      <div
        @click="$refs.fileInput.click()"
        @drop="handleDrop"
        @dragover="handleDragover"
        @dragenter="handleDragover"
        class="px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl">
        <feather-icon icon="UploadIcon" svgClasses="h-16 w-16 stroke-current text-grey" class="block"/>
        <span class="block">Arrastra o selecciona tu archivo XML </span>
        <span v-if="xml"><b>{{xml.name}}</b></span>
      </div>
      <vs-button v-if="!loading" type="filled" @click.prevent="validateXml" class="mt-5 block">Validar</vs-button>
      <vs-progress class="mt-5" v-if="loading" indeterminate color="primary">Validando ...</vs-progress>
    </form>

    <!-- Validation Results -->
    <transition name="fade">
      <div  class="container" id="results"  v-if="result" ref="content">

        <!--todo: make component-->
        <div class="flex flex-wrap items-center justify-between">
          <h2 class="mb-base mr-3 text-primary">Resultado de tu validación</h2>
          <div class="flex items-center">
            <vs-button class="mb-base mr-3 no-print" color="success" @click="printResult" type="filled">Imprimir reporte</vs-button>
          </div>
        </div>

        <!--Detalles del Documento-->
        <div>
          <doc-details :name="result['documentValidResult']['filename']"
                       :hash="result['documentValidResult']['hash']"
                       :signature-type="result['signatureType']"
          />
        </div>

        <!-- Firmantes -->
        <vx-card id="signers" class="mb-5">
          <vs-list-header
            icon="fingerprint"
            title="Firmantes"
            :color="result['documentValidResult']['signerResults'][0]['signatureValid']
                  ? 'success'
                  : 'danger'"
          >
          </vs-list-header>
          <signer v-for="signer in result['documentValidResult']['signerResults']" :key="signer['signerName']"
          :name="signer['signerName']"
          :digest-alg="signer['digestAlgorithm']"
          :is-sig-valid="signer['signatureValid']"
          :sig-date="signer['signatureDateTime']"
          :cert="signer['certResult']"
          :timestamp="signer['timestampResult']">
          </signer>

        </vx-card>

        <!-- Contancia de conservacion NOM-151 -->
        <vx-card id="nom-151">
          <nom
            :is-imp-msg-valid="result['tsrResult']['imprintMessageValid']"
            :is-tsr-valid="result['tsrResult']['success']"
            :policy="result['tsrResult']['policy']"
            :signer="result['tsrResult']['signerResults'][0]"
          >
          </nom>
        </vx-card>

        <div class="vx-row no-print">
            <vs-button class="mt-5 block mr-2" @click="restore" type="filled">Validar otro XML</vs-button>
        </div>

      </div>
    </transition>


  </div>
</template>

<script>

import ValidatorService from '../service/validatorService'
export default {
  props: {
    onSuccess: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      xml: null,
      result: null,
      error: false,
      loading: false
    }
  },
  methods: {

    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.alert('Solo se permite un XML')
        return
      }
      const rawFile = files[0] // only use files[0]
      if (!this.isXml(rawFile)) {
        this.$vs.notify({
          title: 'Archivo invalido',
          text: 'Solo se admiten archivos .XML',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return false
      }
      this.uploadFile(rawFile)
    },

    handleClick (e) {
      const files = e.target.files
      const rawFile = files[0]
      if (!rawFile) return
      this.uploadFile(rawFile)
    },
    isXml (file) {
      return /\.(xml)$/.test(file.name)
    },

    uploadFile (file) {
      this.$refs['fileInput'].value = null
      this.xml = file
    },

    restore () {
      this.xml = null
      this.result = null
      this.error = false
    },

    async validateXml () {
      if (!this.xml) {
        this.alert('Debes seleccionar un XML para validar')
        return false
      }
      const vm = this
      this.loading = true
      await ValidatorService.validateXml(this.xml)
        .then(function (response) {
          vm.result = response.data
        })
        .catch(function (error) {
          vm.error = true
          console.log(error.response.data)
          vm.alert(error.response.data['description'])
        })
        .finally(function () {
          vm.loading = false
        })
    },
    alert (error) {
      this.$vs.dialog({
        color:'warning',
        title: 'Problema de validación',
        acceptText: 'Aceptar',
        text: `${error}`
      })
    },
    printResult () {
      window.print()
    }
  }
}
</script>
